import React, {useState} from "react";
import {observer} from "mobx-react";
import styled from "@emotion/styled";
import {Checkbox, ErrorText, Input, Label, Select} from "views/components/form";
import {Button, FormControl, FormHelperText, MenuItem, Stack} from "@mui/material";
import {useViewController} from "data/services/locator";
import {IFormRegisterController} from "views/components/forms/form_register/form_register.controller";
import {Bindings} from "data/constants/bindings";
import {
	FORM_VALIDATION_ELEMENT_CLASSNAME,
	OPT_IN_TEXT,
	PASSWORD_REQUIREMENTS,
} from "data/constants";
import {Exist} from "views/components/exist/exist.component";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {AdapterLuxon} from "@mui/x-date-pickers/AdapterLuxon";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {css} from "@mui/material/styles";
import {useNavigate} from "react-router-dom";

const Wrapper = styled.div`
	max-width: 320px;
	width: 100%;
`;

const Title = styled.h2`
	color: var(--primaryColor);
	text-align: center;
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: 140%;
	text-transform: capitalize;
	margin-bottom: 12px;
`;

const Form = styled.form`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	gap: 12px;
`;

const DOBWrapper = styled.div`
	width: 100%;

	input {
		padding: 8px 12px;
		color: var(--primaryColor);
	}

	label {
		color: var(--primaryColor);
		padding-left: 12px;
	}
`;

const CheckboxLabel = styled(Label)`
	color: var(--primaryColorDark);
	font-size: 14px;
	font-style: normal;
	font-weight: 450;
	line-height: 140%;

	a,
	b {
		font-weight: 700;
	}

	a {
		text-decoration: underline;
	}
`;

const CheckboxStack = styled(Stack)`
	display: flex;
	align-items: center;
`;

const Checkboxes = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	gap: 10px;
	padding-top: 20px;
	padding-bottom: 12px;
`;

const Action = styled.p`
	color: var(--primaryColorDark);
	text-align: center;
	font-size: 16px;
	font-style: normal;
	font-weight: 450;
	line-height: 140%; /* 22.4px */
	margin-top: 16px;

	a {
		font-weight: bold;
		text-decoration: underline;
	}
`;

const ActionButton = styled(Button)`
	padding: 0;
	height: 16px;
	color: var(--primaryColorDark);
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 100%; /* 16px */
	text-decoration-line: underline;
	width: auto;
`;

const HiddenStep = styled.div<{when: boolean}>`
	display: flex;
	flex-flow: column;
	gap: 12px;
	${(props) =>
		props.when &&
		css`
			position: absolute;
			left: -9999px;
			opacity: 0;
			pointer-events: none;
		`}
`;

const inputProps = {
	className: FORM_VALIDATION_ELEMENT_CLASSNAME,
	"data-required": true,
};

export const FormRegister: React.FC = observer(() => {
	const {
		i18n,
		countries,
		errorMsg,
		isLoading,
		hasErrorFromInitialStep,
		getFormError,
		handleDobChange,
		handleInputFieldChange,
		handleSelectFieldChange,
		handleFormSubmit,
		handleFormChange,
		openLogin,
		isFinalRegistration,
		disableFinalRegistration,
	} = useViewController<IFormRegisterController>(Bindings.FormRegisterController, {
		navigate: useNavigate(),
	});

	const [isOpenCountrySelect, setIsOpenCountrySelect] = useState(false);

	const displayNameHelper = i18n.t(
		"register.display_name.helper",
		"Display name is limited to 10 characters"
	);
	const passwordHelper = i18n.t("register.password.helper", PASSWORD_REQUIREMENTS);

	return (
		<Wrapper>
			<Title>{i18n.t("modal.auth.register", "Register An account")}</Title>

			<Form onSubmit={handleFormSubmit} onChange={handleFormChange}>
				<HiddenStep when={isFinalRegistration}>
					<Action>
						<span>
							{i18n.t("register.navigate.have_account", "Already have an account?")}{" "}
						</span>
						<ActionButton disabled={isLoading} variant="text" onClick={openLogin}>
							{i18n.t("register.navigate.text", "Log In")}
						</ActionButton>
					</Action>

					<FormControl fullWidth>
						<Input
							onInput={handleInputFieldChange}
							label={i18n.t("register.first_name.label", "First Name")}
							name="firstName"
							type="text"
							placeholder={i18n.t("register.first_name.placeholder", "First Name")}
							inputProps={inputProps}
							error={Boolean(getFormError("firstName"))}
							helperText={i18n.t(getFormError("firstName"))}
						/>
					</FormControl>
					<FormControl fullWidth>
						<Input
							onInput={handleInputFieldChange}
							label={i18n.t("register.last_name.label", "Last Name")}
							name="surName"
							type="text"
							placeholder={i18n.t("register.last_name.placeholder", "Last Name")}
							inputProps={inputProps}
							error={Boolean(getFormError("surName"))}
							helperText={i18n.t(getFormError("surName"))}
						/>
					</FormControl>
					<FormControl fullWidth>
						<Input
							onInput={handleInputFieldChange}
							label={i18n.t("register.email.label", "Email Address")}
							name="email"
							placeholder={i18n.t("register.email.placeholder", "Email Address")}
							inputProps={{
								...inputProps,
								"data-type": "email",
							}}
							error={Boolean(getFormError("email"))}
							helperText={i18n.t(getFormError("email"))}
						/>
					</FormControl>
					<FormControl fullWidth>
						<Input
							onInput={handleInputFieldChange}
							label={i18n.t("register.password.label", "Password")}
							name="password"
							type="password"
							placeholder={i18n.t("register.password.placeholder", "Password")}
							inputProps={inputProps}
							error={Boolean(getFormError("password"))}
							helperText={i18n.t(getFormError("password")) || passwordHelper}
						/>
					</FormControl>
					<FormControl fullWidth>
						<Input
							onInput={handleInputFieldChange}
							label={i18n.t("register.confirm_password.label", "Password")}
							name="confirmPassword"
							type="password"
							placeholder={i18n.t(
								"register.confirm_password.placeholder",
								"Password"
							)}
							inputProps={inputProps}
							error={Boolean(getFormError("confirmPassword"))}
							helperText={i18n.t(getFormError("confirmPassword"))}
						/>
					</FormControl>
					<Exist when={countries.length > 0}>
						<FormControl fullWidth>
							<Select
								onChange={(e) => {
									handleSelectFieldChange(e);
									setIsOpenCountrySelect(false);
								}}
								label={i18n.t("register.country.label", "Country of Residence")}
								name="country"
								type="text"
								variant="standard"
								placeholder={i18n.t(
									"register.country.placeholder",
									"Country of Residence"
								)}
								onClose={() => setIsOpenCountrySelect(false)}
								onFocus={() => {
									setTimeout(() => {
										setIsOpenCountrySelect(!isOpenCountrySelect);
									}, 300);
								}}
								onBlur={() => setIsOpenCountrySelect(false)}
								open={isOpenCountrySelect}
								error={Boolean(getFormError("country"))}
								inputProps={inputProps}>
								{countries.map((country) => (
									<MenuItem key={country.code} value={country.code}>
										{country.name}
									</MenuItem>
								))}
							</Select>
							<Exist when={Boolean(getFormError("country"))}>
								<FormHelperText error>
									{i18n.t(getFormError("country"))}
								</FormHelperText>
							</Exist>
						</FormControl>
					</Exist>
					<LocalizationProvider dateAdapter={AdapterLuxon}>
						<DOBWrapper>
							<FormControl fullWidth>
								<DatePicker
									label={i18n.t("register.dob.label", "Date of Birth")}
									disableFuture={true}
									format="dd/MM/yyyy"
									onChange={handleDobChange}
									slotProps={{
										textField: {
											variant: "standard",
											name: "dob",
											error: Boolean(getFormError("dob")),
											helperText: i18n.t(getFormError("dob")),
											inputProps,
										},
									}}
								/>
							</FormControl>
						</DOBWrapper>
					</LocalizationProvider>

					<Button disabled={isLoading || hasErrorFromInitialStep} type="submit">
						{i18n.t("register.form.action_save", "Save")}
					</Button>
				</HiddenStep>

				<HiddenStep when={!isFinalRegistration}>
					<FormControl fullWidth>
						<Input
							onInput={handleInputFieldChange}
							label={i18n.t("register.display_name.label", "Display Name")}
							name="displayName"
							type="text"
							placeholder={i18n.t(
								"register.display_name.placeholder",
								"Display Name"
							)}
							InputProps={{
								inputProps: {
									minLength: 0,
									maxLength: 10,
									...inputProps,
									"data-required": isFinalRegistration,
								},
							}}
							error={Boolean(getFormError("displayName"))}
							helperText={i18n.t(getFormError("displayName")) || displayNameHelper}
						/>
					</FormControl>

					<Checkboxes>
						<FormControl fullWidth>
							<CheckboxStack direction="row" gap={2}>
								<Checkbox
									name="terms"
									id="terms"
									error={Boolean(getFormError("terms"))}
									onChange={handleInputFieldChange}
									className={FORM_VALIDATION_ELEMENT_CLASSNAME}
									data-required={isFinalRegistration}
								/>
								<CheckboxLabel htmlFor="terms">
									<span
										// Disabled due as Loco - is safe place to render HTML
										/* eslint-disable react/no-danger */
										dangerouslySetInnerHTML={{
											__html: i18n.t(
												"registration.terms.link",
												"I have read and accepted the <a href='/help/terms-&-conditions' target='_blank' rel='noreferrer noopener'>Terms & Conditions</a> and confirm I am over the age of 18."
											),
										}}
									/>
								</CheckboxLabel>
							</CheckboxStack>
							<Exist when={Boolean(getFormError("terms"))}>
								<FormHelperText error>
									{i18n.t(getFormError("terms"))}
								</FormHelperText>
							</Exist>
						</FormControl>
						<FormControl fullWidth>
							<CheckboxStack direction="row" gap={2}>
								<Checkbox name="federations" id="federations" />
								<CheckboxLabel htmlFor="federations">
									<span> {i18n.t("registration.opt_in.label", OPT_IN_TEXT)}</span>
								</CheckboxLabel>
							</CheckboxStack>
						</FormControl>
					</Checkboxes>

					<Button disabled={isLoading} type="submit">
						{i18n.t("register.form.action", "Register")}
					</Button>
				</HiddenStep>

				<Exist when={errorMsg}>
					<ErrorText dangerouslySetInnerHTML={{__html: i18n.t(errorMsg || "")}} />
					{isFinalRegistration && (
						<Button onClick={disableFinalRegistration}>Back</Button>
					)}
				</Exist>
			</Form>
		</Wrapper>
	);
});
